/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ScreenNameEnum from './ScreenNameEnum';

/**
 * The UserAccessScreenLogMonthlySummary model module.
 * @module model/UserAccessScreenLogMonthlySummary
 * @version 1.0.0
 */
class UserAccessScreenLogMonthlySummary {
    /**
     * Constructs a new <code>UserAccessScreenLogMonthlySummary</code>.
     * @alias module:model/UserAccessScreenLogMonthlySummary
     */
    constructor() { 
        
        UserAccessScreenLogMonthlySummary.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserAccessScreenLogMonthlySummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserAccessScreenLogMonthlySummary} obj Optional instance to populate.
     * @return {module:model/UserAccessScreenLogMonthlySummary} The populated <code>UserAccessScreenLogMonthlySummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserAccessScreenLogMonthlySummary();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number');
            }
            if (data.hasOwnProperty('screen_name')) {
                obj['screen_name'] = ScreenNameEnum.constructFromObject(data['screen_name']);
            }
            if (data.hasOwnProperty('year')) {
                obj['year'] = ApiClient.convertToType(data['year'], 'Number');
            }
            if (data.hasOwnProperty('month')) {
                obj['month'] = ApiClient.convertToType(data['month'], 'Number');
            }
            if (data.hasOwnProperty('total_access_count')) {
                obj['total_access_count'] = ApiClient.convertToType(data['total_access_count'], 'Number');
            }
            if (data.hasOwnProperty('daily_deduplication_access_count')) {
                obj['daily_deduplication_access_count'] = ApiClient.convertToType(data['daily_deduplication_access_count'], 'Number');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UserAccessScreenLogMonthlySummary</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UserAccessScreenLogMonthlySummary</code>.
     */
    static validateJSON(data) {

        return true;
    }


}



/**
 * @member {Number} id
 */
UserAccessScreenLogMonthlySummary.prototype['id'] = undefined;

/**
 * @member {Number} user_id
 */
UserAccessScreenLogMonthlySummary.prototype['user_id'] = undefined;

/**
 * @member {module:model/ScreenNameEnum} screen_name
 */
UserAccessScreenLogMonthlySummary.prototype['screen_name'] = undefined;

/**
 * @member {Number} year
 */
UserAccessScreenLogMonthlySummary.prototype['year'] = undefined;

/**
 * @member {Number} month
 */
UserAccessScreenLogMonthlySummary.prototype['month'] = undefined;

/**
 * @member {Number} total_access_count
 */
UserAccessScreenLogMonthlySummary.prototype['total_access_count'] = undefined;

/**
 * @member {Number} daily_deduplication_access_count
 */
UserAccessScreenLogMonthlySummary.prototype['daily_deduplication_access_count'] = undefined;

/**
 * @member {Date} created_at
 */
UserAccessScreenLogMonthlySummary.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
UserAccessScreenLogMonthlySummary.prototype['updated_at'] = undefined;






export default UserAccessScreenLogMonthlySummary;

