/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AdminUserMemo from './AdminUserMemo';
import AssessmentProposal from './AssessmentProposal';
import ContractApplication from './ContractApplication';
import DegreeOfConsiderationEnum from './DegreeOfConsiderationEnum';
import InvestmentProposal from './InvestmentProposal';
import JobCategory from './JobCategory';
import JobIndustry from './JobIndustry';
import Master from './Master';
import MoneyAmount from './MoneyAmount';
import Period from './Period';
import ProposalMatching from './ProposalMatching';
import RegisteredStatusEnum from './RegisteredStatusEnum';
import UserAccessScreenLogMonthlySummary from './UserAccessScreenLogMonthlySummary';
import UserDeviceToken from './UserDeviceToken';

/**
 * The User model module.
 * @module model/User
 * @version 1.0.0
 */
class User {
    /**
     * Constructs a new <code>User</code>.
     * @alias module:model/User
     */
    constructor() { 
        
        User.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/User} obj Optional instance to populate.
     * @return {module:model/User} The populated <code>User</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new User();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('identifier')) {
                obj['identifier'] = ApiClient.convertToType(data['identifier'], 'String');
            }
            if (data.hasOwnProperty('family_name')) {
                obj['family_name'] = ApiClient.convertToType(data['family_name'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('family_name_kana')) {
                obj['family_name_kana'] = ApiClient.convertToType(data['family_name_kana'], 'String');
            }
            if (data.hasOwnProperty('first_name_kana')) {
                obj['first_name_kana'] = ApiClient.convertToType(data['first_name_kana'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('phone_number')) {
                obj['phone_number'] = ApiClient.convertToType(data['phone_number'], 'String');
            }
            if (data.hasOwnProperty('birthday')) {
                obj['birthday'] = ApiClient.convertToType(data['birthday'], 'Date');
            }
            if (data.hasOwnProperty('income_tax_rate')) {
                obj['income_tax_rate'] = ApiClient.convertToType(data['income_tax_rate'], 'Number');
            }
            if (data.hasOwnProperty('job_category')) {
                obj['job_category'] = JobCategory.constructFromObject(data['job_category']);
            }
            if (data.hasOwnProperty('address_prefecture')) {
                obj['address_prefecture'] = ApiClient.convertToType(data['address_prefecture'], 'String');
            }
            if (data.hasOwnProperty('job_industry')) {
                obj['job_industry'] = JobIndustry.constructFromObject(data['job_industry']);
            }
            if (data.hasOwnProperty('job_continuous_period')) {
                obj['job_continuous_period'] = Period.constructFromObject(data['job_continuous_period']);
            }
            if (data.hasOwnProperty('financial_asset')) {
                obj['financial_asset'] = MoneyAmount.constructFromObject(data['financial_asset']);
            }
            if (data.hasOwnProperty('annual_income')) {
                obj['annual_income'] = ApiClient.convertToType(data['annual_income'], 'Number');
            }
            if (data.hasOwnProperty('holding_property_count')) {
                obj['holding_property_count'] = ApiClient.convertToType(data['holding_property_count'], 'Number');
            }
            if (data.hasOwnProperty('real_estate_loan_amount')) {
                obj['real_estate_loan_amount'] = ApiClient.convertToType(data['real_estate_loan_amount'], 'Number');
            }
            if (data.hasOwnProperty('current_residence_type_id')) {
                obj['current_residence_type_id'] = ApiClient.convertToType(data['current_residence_type_id'], 'Number');
            }
            if (data.hasOwnProperty('monthly_rent_payment_amount')) {
                obj['monthly_rent_payment_amount'] = ApiClient.convertToType(data['monthly_rent_payment_amount'], 'Number');
            }
            if (data.hasOwnProperty('home_loan_amount')) {
                obj['home_loan_amount'] = ApiClient.convertToType(data['home_loan_amount'], 'Number');
            }
            if (data.hasOwnProperty('other_request_text')) {
                obj['other_request_text'] = ApiClient.convertToType(data['other_request_text'], 'String');
            }
            if (data.hasOwnProperty('data_first_registered_status')) {
                obj['data_first_registered_status'] = RegisteredStatusEnum.constructFromObject(data['data_first_registered_status']);
            }
            if (data.hasOwnProperty('request_the_proposal')) {
                obj['request_the_proposal'] = ApiClient.convertToType(data['request_the_proposal'], 'Boolean');
            }
            if (data.hasOwnProperty('way_to_propose')) {
                obj['way_to_propose'] = ApiClient.convertToType(data['way_to_propose'], 'Number');
            }
            if (data.hasOwnProperty('degree_of_consideration_for_investment')) {
                obj['degree_of_consideration_for_investment'] = DegreeOfConsiderationEnum.constructFromObject(data['degree_of_consideration_for_investment']);
            }
            if (data.hasOwnProperty('degree_of_consideration_for_sale')) {
                obj['degree_of_consideration_for_sale'] = DegreeOfConsiderationEnum.constructFromObject(data['degree_of_consideration_for_sale']);
            }
            if (data.hasOwnProperty('purpose_of_use_free_text')) {
                obj['purpose_of_use_free_text'] = ApiClient.convertToType(data['purpose_of_use_free_text'], 'String');
            }
            if (data.hasOwnProperty('inflow_route_free_text')) {
                obj['inflow_route_free_text'] = ApiClient.convertToType(data['inflow_route_free_text'], 'String');
            }
            if (data.hasOwnProperty('email_confirmed')) {
                obj['email_confirmed'] = ApiClient.convertToType(data['email_confirmed'], 'Boolean');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('password_confirmation')) {
                obj['password_confirmation'] = ApiClient.convertToType(data['password_confirmation'], 'String');
            }
            if (data.hasOwnProperty('last_accessed_at')) {
                obj['last_accessed_at'] = ApiClient.convertToType(data['last_accessed_at'], 'Date');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('account_updated_at')) {
                obj['account_updated_at'] = ApiClient.convertToType(data['account_updated_at'], 'Date');
            }
            if (data.hasOwnProperty('discarded_at')) {
                obj['discarded_at'] = ApiClient.convertToType(data['discarded_at'], 'Date');
            }
            if (data.hasOwnProperty('phone_number_auth_code')) {
                obj['phone_number_auth_code'] = ApiClient.convertToType(data['phone_number_auth_code'], 'Number');
            }
            if (data.hasOwnProperty('assessment_proposals')) {
                obj['assessment_proposals'] = ApiClient.convertToType(data['assessment_proposals'], [AssessmentProposal]);
            }
            if (data.hasOwnProperty('investment_proposals')) {
                obj['investment_proposals'] = ApiClient.convertToType(data['investment_proposals'], [InvestmentProposal]);
            }
            if (data.hasOwnProperty('proposal_matchings')) {
                obj['proposal_matchings'] = ApiClient.convertToType(data['proposal_matchings'], [ProposalMatching]);
            }
            if (data.hasOwnProperty('contract_applications')) {
                obj['contract_applications'] = ApiClient.convertToType(data['contract_applications'], [ContractApplication]);
            }
            if (data.hasOwnProperty('admin_user_memo')) {
                obj['admin_user_memo'] = AdminUserMemo.constructFromObject(data['admin_user_memo']);
            }
            if (data.hasOwnProperty('master_tags')) {
                obj['master_tags'] = ApiClient.convertToType(data['master_tags'], [Master]);
            }
            if (data.hasOwnProperty('master_purpose_of_uses')) {
                obj['master_purpose_of_uses'] = ApiClient.convertToType(data['master_purpose_of_uses'], [Master]);
            }
            if (data.hasOwnProperty('interest_tag_ids')) {
                obj['interest_tag_ids'] = ApiClient.convertToType(data['interest_tag_ids'], ['Number']);
            }
            if (data.hasOwnProperty('master_inflow_routes')) {
                obj['master_inflow_routes'] = ApiClient.convertToType(data['master_inflow_routes'], [Master]);
            }
            if (data.hasOwnProperty('current_residence_type')) {
                obj['current_residence_type'] = Master.constructFromObject(data['current_residence_type']);
            }
            if (data.hasOwnProperty('purpose_of_use_ids')) {
                obj['purpose_of_use_ids'] = ApiClient.convertToType(data['purpose_of_use_ids'], ['Number']);
            }
            if (data.hasOwnProperty('inflow_route_ids')) {
                obj['inflow_route_ids'] = ApiClient.convertToType(data['inflow_route_ids'], ['Number']);
            }
            if (data.hasOwnProperty('user_device_token')) {
                obj['user_device_token'] = UserDeviceToken.constructFromObject(data['user_device_token']);
            }
            if (data.hasOwnProperty('user_access_screen_log_monthly_summaries')) {
                obj['user_access_screen_log_monthly_summaries'] = ApiClient.convertToType(data['user_access_screen_log_monthly_summaries'], [UserAccessScreenLogMonthlySummary]);
            }
            if (data.hasOwnProperty('age')) {
                obj['age'] = ApiClient.convertToType(data['age'], 'Number');
            }
            if (data.hasOwnProperty('is_new_label')) {
                obj['is_new_label'] = ApiClient.convertToType(data['is_new_label'], 'Boolean');
            }
            if (data.hasOwnProperty('is_favorite')) {
                obj['is_favorite'] = ApiClient.convertToType(data['is_favorite'], 'Boolean');
            }
            if (data.hasOwnProperty('loan_amount')) {
                obj['loan_amount'] = ApiClient.convertToType(data['loan_amount'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>User</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>User</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['identifier'] && !(typeof data['identifier'] === 'string' || data['identifier'] instanceof String)) {
            throw new Error("Expected the field `identifier` to be a primitive type in the JSON string but got " + data['identifier']);
        }
        // ensure the json data is a string
        if (data['family_name'] && !(typeof data['family_name'] === 'string' || data['family_name'] instanceof String)) {
            throw new Error("Expected the field `family_name` to be a primitive type in the JSON string but got " + data['family_name']);
        }
        // ensure the json data is a string
        if (data['first_name'] && !(typeof data['first_name'] === 'string' || data['first_name'] instanceof String)) {
            throw new Error("Expected the field `first_name` to be a primitive type in the JSON string but got " + data['first_name']);
        }
        // ensure the json data is a string
        if (data['family_name_kana'] && !(typeof data['family_name_kana'] === 'string' || data['family_name_kana'] instanceof String)) {
            throw new Error("Expected the field `family_name_kana` to be a primitive type in the JSON string but got " + data['family_name_kana']);
        }
        // ensure the json data is a string
        if (data['first_name_kana'] && !(typeof data['first_name_kana'] === 'string' || data['first_name_kana'] instanceof String)) {
            throw new Error("Expected the field `first_name_kana` to be a primitive type in the JSON string but got " + data['first_name_kana']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['phone_number'] && !(typeof data['phone_number'] === 'string' || data['phone_number'] instanceof String)) {
            throw new Error("Expected the field `phone_number` to be a primitive type in the JSON string but got " + data['phone_number']);
        }
        // ensure the json data is a string
        if (data['address_prefecture'] && !(typeof data['address_prefecture'] === 'string' || data['address_prefecture'] instanceof String)) {
            throw new Error("Expected the field `address_prefecture` to be a primitive type in the JSON string but got " + data['address_prefecture']);
        }
        // ensure the json data is a string
        if (data['other_request_text'] && !(typeof data['other_request_text'] === 'string' || data['other_request_text'] instanceof String)) {
            throw new Error("Expected the field `other_request_text` to be a primitive type in the JSON string but got " + data['other_request_text']);
        }
        // ensure the json data is a string
        if (data['purpose_of_use_free_text'] && !(typeof data['purpose_of_use_free_text'] === 'string' || data['purpose_of_use_free_text'] instanceof String)) {
            throw new Error("Expected the field `purpose_of_use_free_text` to be a primitive type in the JSON string but got " + data['purpose_of_use_free_text']);
        }
        // ensure the json data is a string
        if (data['inflow_route_free_text'] && !(typeof data['inflow_route_free_text'] === 'string' || data['inflow_route_free_text'] instanceof String)) {
            throw new Error("Expected the field `inflow_route_free_text` to be a primitive type in the JSON string but got " + data['inflow_route_free_text']);
        }
        // ensure the json data is a string
        if (data['password'] && !(typeof data['password'] === 'string' || data['password'] instanceof String)) {
            throw new Error("Expected the field `password` to be a primitive type in the JSON string but got " + data['password']);
        }
        // ensure the json data is a string
        if (data['password_confirmation'] && !(typeof data['password_confirmation'] === 'string' || data['password_confirmation'] instanceof String)) {
            throw new Error("Expected the field `password_confirmation` to be a primitive type in the JSON string but got " + data['password_confirmation']);
        }
        if (data['assessment_proposals']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['assessment_proposals'])) {
                throw new Error("Expected the field `assessment_proposals` to be an array in the JSON data but got " + data['assessment_proposals']);
            }
            // validate the optional field `assessment_proposals` (array)
            for (const item of data['assessment_proposals']) {
                AssessmentProposal.validateJSON(item);
            };
        }
        if (data['investment_proposals']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['investment_proposals'])) {
                throw new Error("Expected the field `investment_proposals` to be an array in the JSON data but got " + data['investment_proposals']);
            }
            // validate the optional field `investment_proposals` (array)
            for (const item of data['investment_proposals']) {
                InvestmentProposal.validateJSON(item);
            };
        }
        if (data['proposal_matchings']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['proposal_matchings'])) {
                throw new Error("Expected the field `proposal_matchings` to be an array in the JSON data but got " + data['proposal_matchings']);
            }
            // validate the optional field `proposal_matchings` (array)
            for (const item of data['proposal_matchings']) {
                ProposalMatching.validateJSON(item);
            };
        }
        if (data['contract_applications']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['contract_applications'])) {
                throw new Error("Expected the field `contract_applications` to be an array in the JSON data but got " + data['contract_applications']);
            }
            // validate the optional field `contract_applications` (array)
            for (const item of data['contract_applications']) {
                ContractApplication.validateJSON(item);
            };
        }
        // validate the optional field `admin_user_memo`
        if (data['admin_user_memo']) { // data not null
          AdminUserMemo.validateJSON(data['admin_user_memo']);
        }
        if (data['master_tags']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['master_tags'])) {
                throw new Error("Expected the field `master_tags` to be an array in the JSON data but got " + data['master_tags']);
            }
            // validate the optional field `master_tags` (array)
            for (const item of data['master_tags']) {
                Master.validateJSON(item);
            };
        }
        if (data['master_purpose_of_uses']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['master_purpose_of_uses'])) {
                throw new Error("Expected the field `master_purpose_of_uses` to be an array in the JSON data but got " + data['master_purpose_of_uses']);
            }
            // validate the optional field `master_purpose_of_uses` (array)
            for (const item of data['master_purpose_of_uses']) {
                Master.validateJSON(item);
            };
        }
        // ensure the json data is an array
        if (!Array.isArray(data['interest_tag_ids'])) {
            throw new Error("Expected the field `interest_tag_ids` to be an array in the JSON data but got " + data['interest_tag_ids']);
        }
        if (data['master_inflow_routes']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['master_inflow_routes'])) {
                throw new Error("Expected the field `master_inflow_routes` to be an array in the JSON data but got " + data['master_inflow_routes']);
            }
            // validate the optional field `master_inflow_routes` (array)
            for (const item of data['master_inflow_routes']) {
                Master.validateJSON(item);
            };
        }
        // validate the optional field `current_residence_type`
        if (data['current_residence_type']) { // data not null
          Master.validateJSON(data['current_residence_type']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['purpose_of_use_ids'])) {
            throw new Error("Expected the field `purpose_of_use_ids` to be an array in the JSON data but got " + data['purpose_of_use_ids']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['inflow_route_ids'])) {
            throw new Error("Expected the field `inflow_route_ids` to be an array in the JSON data but got " + data['inflow_route_ids']);
        }
        // validate the optional field `user_device_token`
        if (data['user_device_token']) { // data not null
          UserDeviceToken.validateJSON(data['user_device_token']);
        }
        if (data['user_access_screen_log_monthly_summaries']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['user_access_screen_log_monthly_summaries'])) {
                throw new Error("Expected the field `user_access_screen_log_monthly_summaries` to be an array in the JSON data but got " + data['user_access_screen_log_monthly_summaries']);
            }
            // validate the optional field `user_access_screen_log_monthly_summaries` (array)
            for (const item of data['user_access_screen_log_monthly_summaries']) {
                UserAccessScreenLogMonthlySummary.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
User.prototype['id'] = undefined;

/**
 * @member {String} identifier
 */
User.prototype['identifier'] = undefined;

/**
 * @member {String} family_name
 */
User.prototype['family_name'] = undefined;

/**
 * @member {String} first_name
 */
User.prototype['first_name'] = undefined;

/**
 * @member {String} family_name_kana
 */
User.prototype['family_name_kana'] = undefined;

/**
 * @member {String} first_name_kana
 */
User.prototype['first_name_kana'] = undefined;

/**
 * @member {String} email
 */
User.prototype['email'] = undefined;

/**
 * @member {String} phone_number
 */
User.prototype['phone_number'] = undefined;

/**
 * @member {Date} birthday
 */
User.prototype['birthday'] = undefined;

/**
 * @member {Number} income_tax_rate
 */
User.prototype['income_tax_rate'] = undefined;

/**
 * @member {module:model/JobCategory} job_category
 */
User.prototype['job_category'] = undefined;

/**
 * 住所（都道府県）
 * @member {String} address_prefecture
 */
User.prototype['address_prefecture'] = undefined;

/**
 * @member {module:model/JobIndustry} job_industry
 */
User.prototype['job_industry'] = undefined;

/**
 * @member {module:model/Period} job_continuous_period
 */
User.prototype['job_continuous_period'] = undefined;

/**
 * @member {module:model/MoneyAmount} financial_asset
 */
User.prototype['financial_asset'] = undefined;

/**
 * 年収
 * @member {Number} annual_income
 */
User.prototype['annual_income'] = undefined;

/**
 * 保有物件数
 * @member {Number} holding_property_count
 */
User.prototype['holding_property_count'] = undefined;

/**
 * 不動産投資ローン残高合計
 * @member {Number} real_estate_loan_amount
 */
User.prototype['real_estate_loan_amount'] = undefined;

/**
 * 現在のお住まいマスタデータID
 * @member {Number} current_residence_type_id
 */
User.prototype['current_residence_type_id'] = undefined;

/**
 * 自宅毎月家賃額
 * @member {Number} monthly_rent_payment_amount
 */
User.prototype['monthly_rent_payment_amount'] = undefined;

/**
 * 住宅ローン残高合計
 * @member {Number} home_loan_amount
 */
User.prototype['home_loan_amount'] = undefined;

/**
 * その他ご要望
 * @member {String} other_request_text
 */
User.prototype['other_request_text'] = undefined;

/**
 * @member {module:model/RegisteredStatusEnum} data_first_registered_status
 */
User.prototype['data_first_registered_status'] = undefined;

/**
 * 不動産会社からの提案希望 false:希望しない true:希望する 
 * @member {Boolean} request_the_proposal
 */
User.prototype['request_the_proposal'] = undefined;

/**
 * 提案の連絡方法 0:メール 1:LINE 
 * @member {module:model/User.WayToProposeEnum} way_to_propose
 */
User.prototype['way_to_propose'] = undefined;

/**
 * @member {module:model/DegreeOfConsiderationEnum} degree_of_consideration_for_investment
 */
User.prototype['degree_of_consideration_for_investment'] = undefined;

/**
 * @member {module:model/DegreeOfConsiderationEnum} degree_of_consideration_for_sale
 */
User.prototype['degree_of_consideration_for_sale'] = undefined;

/**
 * @member {String} purpose_of_use_free_text
 */
User.prototype['purpose_of_use_free_text'] = undefined;

/**
 * @member {String} inflow_route_free_text
 */
User.prototype['inflow_route_free_text'] = undefined;

/**
 * @member {Boolean} email_confirmed
 */
User.prototype['email_confirmed'] = undefined;

/**
 * @member {String} password
 */
User.prototype['password'] = undefined;

/**
 * @member {String} password_confirmation
 */
User.prototype['password_confirmation'] = undefined;

/**
 * @member {Date} last_accessed_at
 */
User.prototype['last_accessed_at'] = undefined;

/**
 * @member {Date} created_at
 */
User.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
User.prototype['updated_at'] = undefined;

/**
 * @member {Date} account_updated_at
 */
User.prototype['account_updated_at'] = undefined;

/**
 * @member {Date} discarded_at
 */
User.prototype['discarded_at'] = undefined;

/**
 * @member {Number} phone_number_auth_code
 */
User.prototype['phone_number_auth_code'] = undefined;

/**
 * @member {Array.<module:model/AssessmentProposal>} assessment_proposals
 */
User.prototype['assessment_proposals'] = undefined;

/**
 * @member {Array.<module:model/InvestmentProposal>} investment_proposals
 */
User.prototype['investment_proposals'] = undefined;

/**
 * @member {Array.<module:model/ProposalMatching>} proposal_matchings
 */
User.prototype['proposal_matchings'] = undefined;

/**
 * @member {Array.<module:model/ContractApplication>} contract_applications
 */
User.prototype['contract_applications'] = undefined;

/**
 * @member {module:model/AdminUserMemo} admin_user_memo
 */
User.prototype['admin_user_memo'] = undefined;

/**
 * @member {Array.<module:model/Master>} master_tags
 */
User.prototype['master_tags'] = undefined;

/**
 * @member {Array.<module:model/Master>} master_purpose_of_uses
 */
User.prototype['master_purpose_of_uses'] = undefined;

/**
 * 関心のあるタグIDリスト
 * @member {Array.<Number>} interest_tag_ids
 */
User.prototype['interest_tag_ids'] = undefined;

/**
 * @member {Array.<module:model/Master>} master_inflow_routes
 */
User.prototype['master_inflow_routes'] = undefined;

/**
 * @member {module:model/Master} current_residence_type
 */
User.prototype['current_residence_type'] = undefined;

/**
 * 利用目的IDリスト
 * @member {Array.<Number>} purpose_of_use_ids
 */
User.prototype['purpose_of_use_ids'] = undefined;

/**
 * 流入経路IDリスト
 * @member {Array.<Number>} inflow_route_ids
 */
User.prototype['inflow_route_ids'] = undefined;

/**
 * @member {module:model/UserDeviceToken} user_device_token
 */
User.prototype['user_device_token'] = undefined;

/**
 * @member {Array.<module:model/UserAccessScreenLogMonthlySummary>} user_access_screen_log_monthly_summaries
 */
User.prototype['user_access_screen_log_monthly_summaries'] = undefined;

/**
 * @member {Number} age
 */
User.prototype['age'] = undefined;

/**
 * @member {Boolean} is_new_label
 */
User.prototype['is_new_label'] = undefined;

/**
 * @member {Boolean} is_favorite
 */
User.prototype['is_favorite'] = undefined;

/**
 * ローン残高合計
 * @member {Number} loan_amount
 */
User.prototype['loan_amount'] = undefined;





/**
 * Allowed values for the <code>way_to_propose</code> property.
 * @enum {Number}
 * @readonly
 */
User['WayToProposeEnum'] = {

    /**
     * value: 0
     * @const
     */
    "0": 0,

    /**
     * value: 1
     * @const
     */
    "1": 1
};



export default User;

