/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class MatchingType.
* @enum {}
* @readonly
*/
export default class MatchingType {
    
        /**
         * value: "投資"
         * @const
         */
        "投資" = "投資";

    
        /**
         * value: "査定"
         * @const
         */
        "査定" = "査定";

    
        /**
         * value: "手動"
         * @const
         */
        "手動" = "手動";

    

    /**
    * Returns a <code>MatchingType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/MatchingType} The enum <code>MatchingType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

