/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class RegisteredStatusEnum.
* @enum {}
* @readonly
*/
export default class RegisteredStatusEnum {
    
        /**
         * value: "Registration"
         * @const
         */
        "Registration" = "Registration";

    
        /**
         * value: "Account"
         * @const
         */
        "Account" = "Account";

    
        /**
         * value: "Property"
         * @const
         */
        "Property" = "Property";

    

    /**
    * Returns a <code>RegisteredStatusEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/RegisteredStatusEnum} The enum <code>RegisteredStatusEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

