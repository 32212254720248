/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Image from './Image';
import LeaseContract from './LeaseContract';
import MonthlyCashflowResult from './MonthlyCashflowResult';
import PropertyType from './PropertyType';

/**
 * The Property model module.
 * @module model/Property
 * @version 1.0.0
 */
class Property {
    /**
     * Constructs a new <code>Property</code>.
     * @alias module:model/Property
     */
    constructor() { 
        
        Property.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Property</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Property} obj Optional instance to populate.
     * @return {module:model/Property} The populated <code>Property</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Property();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number');
            }
            if (data.hasOwnProperty('property_type')) {
                obj['property_type'] = PropertyType.constructFromObject(data['property_type']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('prefecture')) {
                obj['prefecture'] = ApiClient.convertToType(data['prefecture'], 'String');
            }
            if (data.hasOwnProperty('city')) {
                obj['city'] = ApiClient.convertToType(data['city'], 'String');
            }
            if (data.hasOwnProperty('town')) {
                obj['town'] = ApiClient.convertToType(data['town'], 'String');
            }
            if (data.hasOwnProperty('block')) {
                obj['block'] = ApiClient.convertToType(data['block'], 'String');
            }
            if (data.hasOwnProperty('room_number')) {
                obj['room_number'] = ApiClient.convertToType(data['room_number'], 'String');
            }
            if (data.hasOwnProperty('floor')) {
                obj['floor'] = ApiClient.convertToType(data['floor'], 'Number');
            }
            if (data.hasOwnProperty('railway')) {
                obj['railway'] = ApiClient.convertToType(data['railway'], 'String');
            }
            if (data.hasOwnProperty('station')) {
                obj['station'] = ApiClient.convertToType(data['station'], 'String');
            }
            if (data.hasOwnProperty('walk')) {
                obj['walk'] = ApiClient.convertToType(data['walk'], 'Number');
            }
            if (data.hasOwnProperty('bought_at')) {
                obj['bought_at'] = ApiClient.convertToType(data['bought_at'], 'Date');
            }
            if (data.hasOwnProperty('built_at')) {
                obj['built_at'] = ApiClient.convertToType(data['built_at'], 'Date');
            }
            if (data.hasOwnProperty('structure')) {
                obj['structure'] = ApiClient.convertToType(data['structure'], 'String');
            }
            if (data.hasOwnProperty('occupied_area_m2')) {
                obj['occupied_area_m2'] = ApiClient.convertToType(data['occupied_area_m2'], 'Number');
            }
            if (data.hasOwnProperty('layout')) {
                obj['layout'] = ApiClient.convertToType(data['layout'], 'String');
            }
            if (data.hasOwnProperty('total_price_of_property')) {
                obj['total_price_of_property'] = ApiClient.convertToType(data['total_price_of_property'], 'Number');
            }
            if (data.hasOwnProperty('priceof_building')) {
                obj['priceof_building'] = ApiClient.convertToType(data['priceof_building'], 'Number');
            }
            if (data.hasOwnProperty('priceof_facilities')) {
                obj['priceof_facilities'] = ApiClient.convertToType(data['priceof_facilities'], 'Number');
            }
            if (data.hasOwnProperty('priceof_land')) {
                obj['priceof_land'] = ApiClient.convertToType(data['priceof_land'], 'Number');
            }
            if (data.hasOwnProperty('priceof_tax')) {
                obj['priceof_tax'] = ApiClient.convertToType(data['priceof_tax'], 'Number');
            }
            if (data.hasOwnProperty('financial_institution')) {
                obj['financial_institution'] = ApiClient.convertToType(data['financial_institution'], 'String');
            }
            if (data.hasOwnProperty('loan_amount')) {
                obj['loan_amount'] = ApiClient.convertToType(data['loan_amount'], 'Number');
            }
            if (data.hasOwnProperty('payment_starts_at')) {
                obj['payment_starts_at'] = ApiClient.convertToType(data['payment_starts_at'], 'Date');
            }
            if (data.hasOwnProperty('payment_period')) {
                obj['payment_period'] = ApiClient.convertToType(data['payment_period'], 'Number');
            }
            if (data.hasOwnProperty('rental_management_company')) {
                obj['rental_management_company'] = ApiClient.convertToType(data['rental_management_company'], 'String');
            }
            if (data.hasOwnProperty('management_type')) {
                obj['management_type'] = ApiClient.convertToType(data['management_type'], 'String');
            }
            if (data.hasOwnProperty('sales_status')) {
                obj['sales_status'] = ApiClient.convertToType(data['sales_status'], 'String');
            }
            if (data.hasOwnProperty('property_acquisition_tax')) {
                obj['property_acquisition_tax'] = ApiClient.convertToType(data['property_acquisition_tax'], 'Number');
            }
            if (data.hasOwnProperty('property_acquisition_tax_payment_month')) {
                obj['property_acquisition_tax_payment_month'] = ApiClient.convertToType(data['property_acquisition_tax_payment_month'], 'Date');
            }
            if (data.hasOwnProperty('misc_expences_at_bought')) {
                obj['misc_expences_at_bought'] = ApiClient.convertToType(data['misc_expences_at_bought'], 'Number');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('discarded_at')) {
                obj['discarded_at'] = ApiClient.convertToType(data['discarded_at'], 'Date');
            }
            if (data.hasOwnProperty('images')) {
                obj['images'] = ApiClient.convertToType(data['images'], [Image]);
            }
            if (data.hasOwnProperty('lease_contracts')) {
                obj['lease_contracts'] = ApiClient.convertToType(data['lease_contracts'], [LeaseContract]);
            }
            if (data.hasOwnProperty('monthly_cashflow_results')) {
                obj['monthly_cashflow_results'] = ApiClient.convertToType(data['monthly_cashflow_results'], [MonthlyCashflowResult]);
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = Image.constructFromObject(data['image']);
            }
            if (data.hasOwnProperty('lease_contract_latest')) {
                obj['lease_contract_latest'] = LeaseContract.constructFromObject(data['lease_contract_latest']);
            }
            if (data.hasOwnProperty('lease_contract_current')) {
                obj['lease_contract_current'] = LeaseContract.constructFromObject(data['lease_contract_current']);
            }
            if (data.hasOwnProperty('monthly_cashflow_result_latest')) {
                obj['monthly_cashflow_result_latest'] = MonthlyCashflowResult.constructFromObject(data['monthly_cashflow_result_latest']);
            }
            if (data.hasOwnProperty('is_new_label')) {
                obj['is_new_label'] = ApiClient.convertToType(data['is_new_label'], 'Boolean');
            }
            if (data.hasOwnProperty('is_favorite')) {
                obj['is_favorite'] = ApiClient.convertToType(data['is_favorite'], 'Boolean');
            }
            if (data.hasOwnProperty('remain_loan_amount')) {
                obj['remain_loan_amount'] = ApiClient.convertToType(data['remain_loan_amount'], 'Number');
            }
            if (data.hasOwnProperty('monthly_total_fee')) {
                obj['monthly_total_fee'] = ApiClient.convertToType(data['monthly_total_fee'], 'Number');
            }
            if (data.hasOwnProperty('monthly_building_total_cost')) {
                obj['monthly_building_total_cost'] = ApiClient.convertToType(data['monthly_building_total_cost'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Property</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Property</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['prefecture'] && !(typeof data['prefecture'] === 'string' || data['prefecture'] instanceof String)) {
            throw new Error("Expected the field `prefecture` to be a primitive type in the JSON string but got " + data['prefecture']);
        }
        // ensure the json data is a string
        if (data['city'] && !(typeof data['city'] === 'string' || data['city'] instanceof String)) {
            throw new Error("Expected the field `city` to be a primitive type in the JSON string but got " + data['city']);
        }
        // ensure the json data is a string
        if (data['town'] && !(typeof data['town'] === 'string' || data['town'] instanceof String)) {
            throw new Error("Expected the field `town` to be a primitive type in the JSON string but got " + data['town']);
        }
        // ensure the json data is a string
        if (data['block'] && !(typeof data['block'] === 'string' || data['block'] instanceof String)) {
            throw new Error("Expected the field `block` to be a primitive type in the JSON string but got " + data['block']);
        }
        // ensure the json data is a string
        if (data['room_number'] && !(typeof data['room_number'] === 'string' || data['room_number'] instanceof String)) {
            throw new Error("Expected the field `room_number` to be a primitive type in the JSON string but got " + data['room_number']);
        }
        // ensure the json data is a string
        if (data['railway'] && !(typeof data['railway'] === 'string' || data['railway'] instanceof String)) {
            throw new Error("Expected the field `railway` to be a primitive type in the JSON string but got " + data['railway']);
        }
        // ensure the json data is a string
        if (data['station'] && !(typeof data['station'] === 'string' || data['station'] instanceof String)) {
            throw new Error("Expected the field `station` to be a primitive type in the JSON string but got " + data['station']);
        }
        // ensure the json data is a string
        if (data['structure'] && !(typeof data['structure'] === 'string' || data['structure'] instanceof String)) {
            throw new Error("Expected the field `structure` to be a primitive type in the JSON string but got " + data['structure']);
        }
        // ensure the json data is a string
        if (data['layout'] && !(typeof data['layout'] === 'string' || data['layout'] instanceof String)) {
            throw new Error("Expected the field `layout` to be a primitive type in the JSON string but got " + data['layout']);
        }
        // ensure the json data is a string
        if (data['financial_institution'] && !(typeof data['financial_institution'] === 'string' || data['financial_institution'] instanceof String)) {
            throw new Error("Expected the field `financial_institution` to be a primitive type in the JSON string but got " + data['financial_institution']);
        }
        // ensure the json data is a string
        if (data['rental_management_company'] && !(typeof data['rental_management_company'] === 'string' || data['rental_management_company'] instanceof String)) {
            throw new Error("Expected the field `rental_management_company` to be a primitive type in the JSON string but got " + data['rental_management_company']);
        }
        // ensure the json data is a string
        if (data['management_type'] && !(typeof data['management_type'] === 'string' || data['management_type'] instanceof String)) {
            throw new Error("Expected the field `management_type` to be a primitive type in the JSON string but got " + data['management_type']);
        }
        // ensure the json data is a string
        if (data['sales_status'] && !(typeof data['sales_status'] === 'string' || data['sales_status'] instanceof String)) {
            throw new Error("Expected the field `sales_status` to be a primitive type in the JSON string but got " + data['sales_status']);
        }
        if (data['images']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['images'])) {
                throw new Error("Expected the field `images` to be an array in the JSON data but got " + data['images']);
            }
            // validate the optional field `images` (array)
            for (const item of data['images']) {
                Image.validateJSON(item);
            };
        }
        if (data['lease_contracts']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['lease_contracts'])) {
                throw new Error("Expected the field `lease_contracts` to be an array in the JSON data but got " + data['lease_contracts']);
            }
            // validate the optional field `lease_contracts` (array)
            for (const item of data['lease_contracts']) {
                LeaseContract.validateJSON(item);
            };
        }
        if (data['monthly_cashflow_results']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['monthly_cashflow_results'])) {
                throw new Error("Expected the field `monthly_cashflow_results` to be an array in the JSON data but got " + data['monthly_cashflow_results']);
            }
            // validate the optional field `monthly_cashflow_results` (array)
            for (const item of data['monthly_cashflow_results']) {
                MonthlyCashflowResult.validateJSON(item);
            };
        }
        // validate the optional field `image`
        if (data['image']) { // data not null
          Image.validateJSON(data['image']);
        }
        // validate the optional field `lease_contract_latest`
        if (data['lease_contract_latest']) { // data not null
          LeaseContract.validateJSON(data['lease_contract_latest']);
        }
        // validate the optional field `lease_contract_current`
        if (data['lease_contract_current']) { // data not null
          LeaseContract.validateJSON(data['lease_contract_current']);
        }
        // validate the optional field `monthly_cashflow_result_latest`
        if (data['monthly_cashflow_result_latest']) { // data not null
          MonthlyCashflowResult.validateJSON(data['monthly_cashflow_result_latest']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
Property.prototype['id'] = undefined;

/**
 * @member {Number} user_id
 */
Property.prototype['user_id'] = undefined;

/**
 * @member {module:model/PropertyType} property_type
 */
Property.prototype['property_type'] = undefined;

/**
 * @member {String} name
 */
Property.prototype['name'] = undefined;

/**
 * @member {String} prefecture
 */
Property.prototype['prefecture'] = undefined;

/**
 * @member {String} city
 */
Property.prototype['city'] = undefined;

/**
 * @member {String} town
 */
Property.prototype['town'] = undefined;

/**
 * @member {String} block
 */
Property.prototype['block'] = undefined;

/**
 * @member {String} room_number
 */
Property.prototype['room_number'] = undefined;

/**
 * @member {Number} floor
 */
Property.prototype['floor'] = undefined;

/**
 * @member {String} railway
 */
Property.prototype['railway'] = undefined;

/**
 * @member {String} station
 */
Property.prototype['station'] = undefined;

/**
 * @member {Number} walk
 */
Property.prototype['walk'] = undefined;

/**
 * @member {Date} bought_at
 */
Property.prototype['bought_at'] = undefined;

/**
 * @member {Date} built_at
 */
Property.prototype['built_at'] = undefined;

/**
 * @member {module:model/Property.StructureEnum} structure
 */
Property.prototype['structure'] = undefined;

/**
 * @member {Number} occupied_area_m2
 */
Property.prototype['occupied_area_m2'] = undefined;

/**
 * @member {module:model/Property.LayoutEnum} layout
 */
Property.prototype['layout'] = undefined;

/**
 * 購入価格 (下記priceof_* の合計)
 * @member {Number} total_price_of_property
 */
Property.prototype['total_price_of_property'] = undefined;

/**
 * うち建物
 * @member {Number} priceof_building
 */
Property.prototype['priceof_building'] = undefined;

/**
 * うち設備
 * @member {Number} priceof_facilities
 */
Property.prototype['priceof_facilities'] = undefined;

/**
 * うち土地
 * @member {Number} priceof_land
 */
Property.prototype['priceof_land'] = undefined;

/**
 * うち消費税
 * @member {Number} priceof_tax
 */
Property.prototype['priceof_tax'] = undefined;

/**
 * 金融機関
 * @member {String} financial_institution
 */
Property.prototype['financial_institution'] = undefined;

/**
 * ローン総額
 * @member {Number} loan_amount
 */
Property.prototype['loan_amount'] = undefined;

/**
 * 支払い開始月
 * @member {Date} payment_starts_at
 */
Property.prototype['payment_starts_at'] = undefined;

/**
 * 支払い期間（年）
 * @member {Number} payment_period
 */
Property.prototype['payment_period'] = undefined;

/**
 * 賃貸管理会社
 * @member {String} rental_management_company
 */
Property.prototype['rental_management_company'] = undefined;

/**
 * 管理形態
 * @member {module:model/Property.ManagementTypeEnum} management_type
 */
Property.prototype['management_type'] = undefined;

/**
 * 売却状況
 * @member {module:model/Property.SalesStatusEnum} sales_status
 */
Property.prototype['sales_status'] = undefined;

/**
 * 不動産取得税
 * @member {Number} property_acquisition_tax
 */
Property.prototype['property_acquisition_tax'] = undefined;

/**
 * 不動産取得税の支払い月
 * @member {Date} property_acquisition_tax_payment_month
 */
Property.prototype['property_acquisition_tax_payment_month'] = undefined;

/**
 * 購入時諸費用
 * @member {Number} misc_expences_at_bought
 */
Property.prototype['misc_expences_at_bought'] = undefined;

/**
 * @member {Date} created_at
 */
Property.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
Property.prototype['updated_at'] = undefined;

/**
 * @member {Date} discarded_at
 */
Property.prototype['discarded_at'] = undefined;

/**
 * @member {Array.<module:model/Image>} images
 */
Property.prototype['images'] = undefined;

/**
 * @member {Array.<module:model/LeaseContract>} lease_contracts
 */
Property.prototype['lease_contracts'] = undefined;

/**
 * @member {Array.<module:model/MonthlyCashflowResult>} monthly_cashflow_results
 */
Property.prototype['monthly_cashflow_results'] = undefined;

/**
 * @member {module:model/Image} image
 */
Property.prototype['image'] = undefined;

/**
 * @member {module:model/LeaseContract} lease_contract_latest
 */
Property.prototype['lease_contract_latest'] = undefined;

/**
 * @member {module:model/LeaseContract} lease_contract_current
 */
Property.prototype['lease_contract_current'] = undefined;

/**
 * @member {module:model/MonthlyCashflowResult} monthly_cashflow_result_latest
 */
Property.prototype['monthly_cashflow_result_latest'] = undefined;

/**
 * 新着物件かどうか
 * @member {Boolean} is_new_label
 */
Property.prototype['is_new_label'] = undefined;

/**
 * お気に入り登録済みかどうか
 * @member {Boolean} is_favorite
 */
Property.prototype['is_favorite'] = undefined;

/**
 * ローン残債
 * @member {Number} remain_loan_amount
 */
Property.prototype['remain_loan_amount'] = undefined;

/**
 * 月額家賃収入 + 月額管理費収入
 * @member {Number} monthly_total_fee
 */
Property.prototype['monthly_total_fee'] = undefined;

/**
 * 月額建物管理費 + 月額修繕積立金
 * @member {Number} monthly_building_total_cost
 */
Property.prototype['monthly_building_total_cost'] = undefined;





/**
 * Allowed values for the <code>structure</code> property.
 * @enum {String}
 * @readonly
 */
Property['StructureEnum'] = {

    /**
     * value: "未選択"
     * @const
     */
    "未選択": "未選択",

    /**
     * value: "SRC造(鉄骨鉄筋コンクリート造)"
     * @const
     */
    "SRC造(鉄骨鉄筋コンクリート造)": "SRC造(鉄骨鉄筋コンクリート造)",

    /**
     * value: "RC造(鉄筋コンクリート造)"
     * @const
     */
    "RC造(鉄筋コンクリート造)": "RC造(鉄筋コンクリート造)",

    /**
     * value: "S造(重量鉄骨造)"
     * @const
     */
    "S造(重量鉄骨造)": "S造(重量鉄骨造)",

    /**
     * value: "木造"
     * @const
     */
    "木造": "木造"
};


/**
 * Allowed values for the <code>layout</code> property.
 * @enum {String}
 * @readonly
 */
Property['LayoutEnum'] = {

    /**
     * value: "未選択"
     * @const
     */
    "未選択": "未選択",

    /**
     * value: "1R"
     * @const
     */
    "1R": "1R",

    /**
     * value: "1K"
     * @const
     */
    "1K": "1K",

    /**
     * value: "1LK/1LDK"
     * @const
     */
    "1LK/1LDK": "1LK/1LDK",

    /**
     * value: "2L/2DK"
     * @const
     */
    "2L/2DK": "2L/2DK",

    /**
     * value: "2LK/2LDK"
     * @const
     */
    "2LK/2LDK": "2LK/2LDK",

    /**
     * value: "3K/3DK"
     * @const
     */
    "3K/3DK": "3K/3DK",

    /**
     * value: "3LK/3LDK"
     * @const
     */
    "3LK/3LDK": "3LK/3LDK",

    /**
     * value: "4K/4DK"
     * @const
     */
    "4K/4DK": "4K/4DK",

    /**
     * value: "4LK/4LDK"
     * @const
     */
    "4LK/4LDK": "4LK/4LDK",

    /**
     * value: "5K/5DK"
     * @const
     */
    "5K/5DK": "5K/5DK",

    /**
     * value: "5LK/5LDK"
     * @const
     */
    "5LK/5LDK": "5LK/5LDK",

    /**
     * value: "6K/6DK"
     * @const
     */
    "6K/6DK": "6K/6DK"
};


/**
 * Allowed values for the <code>management_type</code> property.
 * @enum {String}
 * @readonly
 */
Property['ManagementTypeEnum'] = {

    /**
     * value: "NotSelected"
     * @const
     */
    "NotSelected": "NotSelected",

    /**
     * value: "Outsourcing"
     * @const
     */
    "Outsourcing": "Outsourcing",

    /**
     * value: "MoneyCollectionAgency"
     * @const
     */
    "MoneyCollectionAgency": "MoneyCollectionAgency",

    /**
     * value: "SelfManagement"
     * @const
     */
    "SelfManagement": "SelfManagement",

    /**
     * value: "Subleasing"
     * @const
     */
    "Subleasing": "Subleasing"
};


/**
 * Allowed values for the <code>sales_status</code> property.
 * @enum {String}
 * @readonly
 */
Property['SalesStatusEnum'] = {

    /**
     * value: "未選択"
     * @const
     */
    "未選択": "未選択",

    /**
     * value: "売りに出していない"
     * @const
     */
    "売りに出していない": "売りに出していない",

    /**
     * value: "一般媒介契約締結済み"
     * @const
     */
    "一般媒介契約締結済み": "一般媒介契約締結済み",

    /**
     * value: "専任媒介契約締結済み"
     * @const
     */
    "専任媒介契約締結済み": "専任媒介契約締結済み",

    /**
     * value: "専属専任媒介締結済み"
     * @const
     */
    "専属専任媒介締結済み": "専属専任媒介締結済み"
};



export default Property;

