import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import CancelIcon from "@mui/icons-material/Cancel";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as z from "zod";
import {
  PropallyAgentV1Api,
  Id,
  ResponseMessage,
  InvestmentProposal,
  InvestmentProposalAttachmentFile,
  InvestmentProposalRequest,
} from "../../api/generated/src";
import Button from "../../components/Atoms/Button";
import TextField from "../../components/Atoms/TextField";
import { ConfirmDialog } from "../../components/Molecules/ConfirmDialog";
import { DoingOverlay } from "../../components/Organisms/DoingOverlay";
import { MAX_LENGTH_ERROR, REQUIRED_ERROR } from "../../utils/message";
import Modal from "../Molecules/Modal";

const registerProposal = async (userId: number, proposal_content: string): Promise<Id> => {
  const request = {
    user_id: userId,
    proposal_content: proposal_content,
  } as InvestmentProposalRequest;
  return new Promise((resolve, reject) => {
    new PropallyAgentV1Api().agentV1InvestmentProposalsPost(
      {
        investmentProposalRequest: request,
      },
      (error: object, data: Id, response: object) => {
        if (error) {
          console.error(error);
          reject(error);
        } else {
          resolve(data);
        }
      }
    );
  });
};

const updateProposal = async (id: number, proposal_content: string): Promise<ResponseMessage> => {
  const request = {
    proposal_content: proposal_content,
  } as InvestmentProposalRequest;
  return new Promise((resolve, reject) => {
    new PropallyAgentV1Api().agentV1InvestmentProposalsInvestmentProposalIdPatch(
      id,
      {
        investmentProposalRequest: request,
      },
      (error: object, data: ResponseMessage, response: object) => {
        if (error) {
          console.error(error);
          reject(error);
        } else {
          resolve(data);
        }
      }
    );
  });
};

const postProposalFile = async (id: number, file: File): Promise<ResponseMessage> => {
  return new Promise((resolve, reject) => {
    new PropallyAgentV1Api().agentV1InvestmentProposalsInvestmentProposalIdFilesPost(
      id,
      { imageBinary: file },
      (error: object, data: ResponseMessage, response: object) => {
        if (error) {
          reject(response);
        } else {
          resolve(data);
        }
      }
    );
  });
};

const StyledAccordion = styled(Accordion)`
  &.Mui-expanded {
    margin-top: 0;
  }
`;
const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 2rem;
  background-color: #f5f5f5;
  height: 40px;
  min-height: 40px;
  font-weight: bold;
  &.Mui-expanded {
    min-height: 40px;
  }
`;
const registerProposalSchema = z.object({
  proposal_content: z.string().min(1, REQUIRED_ERROR).max(200, MAX_LENGTH_ERROR(200)),
  files: z.array(z.custom<File>()),
});
type RegisterProposalSchema = z.infer<typeof registerProposalSchema>;

// Propsには登録時と編集時でそれぞれ以下を渡す
// 呼び出し元 | userId     | proposal
// ----------|------------|---------
// 登録時     |対象ユーザID配列 | undefined
// 編集時     |undefined   | 対象提案情報
interface Props {
  userIds?: number[];
  proposal?: InvestmentProposal;
}

const InvestmentProposalMessage: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const [hasProposed] = useState<boolean>(!!props.proposal);
  const [sendingStatus, setSendingStatus] = useState<"notSending" | "sending" | "hasSent">("notSending");
  const [files, setFiles] = useState<File[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const methods = useForm<RegisterProposalSchema>({
    resolver: zodResolver(registerProposalSchema),
    mode: "onBlur",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      proposal_content: props.proposal?.proposal_content,
      files: [],
    },
  });

  const sentFileUrls: string[] = props.proposal?.investment_proposal_attachment_files.map(
    (v: InvestmentProposalAttachmentFile) => v.file_url
  );

  const onSubmit: SubmitHandler<RegisterProposalSchema> = (data) => {
    if (hasProposed) {
      // 提案済みの場合は確認モーダルを表示するのみ(モーダルにてsubmit後に更新処理をしているため)
      setModalOpen(true);
      return;
    }

    setSendingStatus("sending");
    props.userIds?.forEach((userId) => {
      registerProposal(userId, data.proposal_content)
        .then((response) => {
          const fns = [...data.files.map((file) => postProposalFile(response.id, file))];
          (async () => {
            await Promise.all([...fns]).catch((error) => {
              alert("エラーが発生しました。\n時間をおいて再度お試しください。");
              console.error(error);
            });
          })();
        })
        .catch((error) => {
          console.error(error);
          setSendingStatus("notSending");
        });
    });
    setSendingStatus("hasSent");
  };

  const handleClickProcessButton = () => {
    const data = methods.getValues();
    setSendingStatus("sending");
    updateProposal(props.proposal?.id, data.proposal_content)
      .then(() => {
        const fns = [...data.files.map((file) => postProposalFile(props.proposal?.id, file))];
        (async () => {
          await Promise.all([...fns])
            .then(() => {
              setSendingStatus("hasSent");
            })
            .catch((error) => {
              alert("エラーが発生しました。\n時間をおいて再度お試しください。");
              console.error(error);
            });
        })();
      })
      .catch((error) => {
        console.error(error);
        setSendingStatus("notSending");
      });
  };

  const handleOnAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: 同一ファイル名がある場合は追加しないようにする
    if (!e.target.files) return;
    // NOTE: setState は更新が非同期で、タイミングの保証が難しいため setValue を先に実行する
    methods.setValue("files", [...files, ...e.target.files]);
    setFiles([...files, ...e.target.files]);
  };

  const handleOnRemoveImage = (index: number) => {
    const newImages = [...files];
    newImages.splice(index, 1);

    // NOTE: setState は更新が非同期で、タイミングの保証が難しいため setValue を先に実行する
    methods.setValue("files", newImages);
    setFiles(newImages);
  };

  if (sendingStatus === "sending") return <DoingOverlay open={true} text="送信中..." />;

  const previewList = files.map((file, i) => (
    <Box
      key={file.name}
      sx={{
        position: "relative",
        width: "80px",
        m: 2,
      }}
    >
      <IconButton
        aria-label="delete image"
        sx={{
          position: "absolute",
          top: -15,
          left: -15,
          color: "#aaa",
        }}
        onClick={() => handleOnRemoveImage(i)}
      >
        <CancelIcon />
      </IconButton>
      <img
        src={file.type !== "application/pdf" ? URL.createObjectURL(file) : "/pdf_icon.svg"}
        style={{
          width: "100%",
          maxWidth: "80px",
          maxHeight: "80px",
        }}
      />
      <Box
        sx={{
          width: "80px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography variant="caption">{file.name}</Typography>
      </Box>
    </Box>
  ));

  return (
    <>
      <StyledAccordion expanded={true}>
        <StyledAccordionSummary aria-controls={"proposal-input"} sx={{ height: 80, p: 0 }}>
          提案メッセージ
        </StyledAccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box mx={4} my={2}>
              <Controller
                name="proposal_content"
                control={methods.control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={error?.message}
                    placeholder="本文を入力してください"
                    multiline
                    rows={6}
                    fullWidth
                  />
                )}
              />
              <Stack direction="row" justifyContent="space-between" sx={{ mt: "16px" }}>
                {/* TODO: 2024/07/20 user UI/UX renewal対応の添付ファイル表示実装が間に合っていないため対応までコメントアウト
                <Stack direction="row" alignItems="center">
                  <label htmlFor="file-input-property">
                    <Button
                      // TODO: 送信可能ファイル最大数を確認する
                      // disabled={images.length >= maxImagesUpload}
                      label="ファイル選択"
                      component="span"
                      variant="outlined"
                      fronticon="attachFile"
                    />
                    <input
                      id="file-input-property"
                      type="file"
                      multiple
                      accept="image/png,image/jpg,image/jpeg,image/gif,application/pdf"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnAddImage(e)}
                      style={{ display: "none" }}
                    />
                  </label>
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    画像（JPG, PNG, GIF）またはPDF 10MB以下
                  </Typography>
                </Stack>*/}
                {hasProposed ? (
                  <Button
                    label="再送信"
                    type="submit"
                    size="large"
                    sx={{ m: "16px" }}
                    style={{ background: "#5772FF" }}
                  />
                ) : (
                  <Button label="送信" type="submit" size="large" />
                )}
              </Stack>
              {/* TODO: 2024/07/20 user UI/UX renewal対応の添付ファイル表示未対応のため対応完了までコメントアウト
              {hasProposed &&
                <Stack direction="row" sx={{ m: 2 }} alignItems="center" gap={2}>
                  {sentFileUrls && sentFileUrls.map((url, index) => (
                    <Button
                      key={index}
                      fronticon="attachFile"
                      label={`送信済ファイル ${index + 1}`}
                      onClick={() => window.open(url, "_blank")}
                    />
                  ))}
                </Stack>
              }
              <Stack direction="row" sx={{ m: 2 }} alignItems="center"></Stack>
              <Stack direction="row" sx={{ m: 2 }} alignItems="center">
                {previewList}
              </Stack>*/}
            </Box>
          </form>
        </AccordionDetails>
      </StyledAccordion>
      {hasProposed && (
        <Modal
          isDisplayed={modalOpen}
          insideText={"このユーザーへ既に投資提案しています。\n前回の投資提案を取り消して再提案しますか？"}
          onClickProcessButton={handleClickProcessButton}
          onClickCancelButton={() => setModalOpen(false)}
          processBLabel="提案する"
          cancelBLabel="キャンセル"
          variant="twoButton"
        />
      )}
      <ConfirmDialog
        isDisplayed={sendingStatus === "hasSent"}
        insideText="送信完了しました"
        ButtonLabel="閉じる"
        onClickButton={() => navigate(-1)}
      />
    </>
  );
};

export default InvestmentProposalMessage;
