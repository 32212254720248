/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import AssessmentProposalAttachmentFile from './AssessmentProposalAttachmentFile';
import Property from './Property';
import ProposalStatusEnum from './ProposalStatusEnum';
import User from './User';

/**
 * The AssessmentProposal model module.
 * @module model/AssessmentProposal
 * @version 1.0.0
 */
class AssessmentProposal {
    /**
     * Constructs a new <code>AssessmentProposal</code>.
     * @alias module:model/AssessmentProposal
     */
    constructor() { 
        
        AssessmentProposal.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AssessmentProposal</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AssessmentProposal} obj Optional instance to populate.
     * @return {module:model/AssessmentProposal} The populated <code>AssessmentProposal</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AssessmentProposal();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('agent_id')) {
                obj['agent_id'] = ApiClient.convertToType(data['agent_id'], 'Number');
            }
            if (data.hasOwnProperty('property_id')) {
                obj['property_id'] = ApiClient.convertToType(data['property_id'], 'Number');
            }
            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'Number');
            }
            if (data.hasOwnProperty('proposal_price')) {
                obj['proposal_price'] = ApiClient.convertToType(data['proposal_price'], 'Number');
            }
            if (data.hasOwnProperty('proposal_content')) {
                obj['proposal_content'] = ApiClient.convertToType(data['proposal_content'], 'String');
            }
            if (data.hasOwnProperty('is_user_read')) {
                obj['is_user_read'] = ApiClient.convertToType(data['is_user_read'], 'Boolean');
            }
            if (data.hasOwnProperty('is_user_approved')) {
                obj['is_user_approved'] = ApiClient.convertToType(data['is_user_approved'], 'Boolean');
            }
            if (data.hasOwnProperty('trade_type')) {
                obj['trade_type'] = ApiClient.convertToType(data['trade_type'], 'Number');
            }
            if (data.hasOwnProperty('proposal_date')) {
                obj['proposal_date'] = ApiClient.convertToType(data['proposal_date'], 'Date');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = User.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('property')) {
                obj['property'] = Property.constructFromObject(data['property']);
            }
            if (data.hasOwnProperty('assessment_proposal_attachment_files')) {
                obj['assessment_proposal_attachment_files'] = ApiClient.convertToType(data['assessment_proposal_attachment_files'], [AssessmentProposalAttachmentFile]);
            }
            if (data.hasOwnProperty('proposal_status')) {
                obj['proposal_status'] = ProposalStatusEnum.constructFromObject(data['proposal_status']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AssessmentProposal</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AssessmentProposal</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['proposal_content'] && !(typeof data['proposal_content'] === 'string' || data['proposal_content'] instanceof String)) {
            throw new Error("Expected the field `proposal_content` to be a primitive type in the JSON string but got " + data['proposal_content']);
        }
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        // validate the optional field `user`
        if (data['user']) { // data not null
          User.validateJSON(data['user']);
        }
        // validate the optional field `property`
        if (data['property']) { // data not null
          Property.validateJSON(data['property']);
        }
        if (data['assessment_proposal_attachment_files']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['assessment_proposal_attachment_files'])) {
                throw new Error("Expected the field `assessment_proposal_attachment_files` to be an array in the JSON data but got " + data['assessment_proposal_attachment_files']);
            }
            // validate the optional field `assessment_proposal_attachment_files` (array)
            for (const item of data['assessment_proposal_attachment_files']) {
                AssessmentProposalAttachmentFile.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
AssessmentProposal.prototype['id'] = undefined;

/**
 * @member {Number} agent_id
 */
AssessmentProposal.prototype['agent_id'] = undefined;

/**
 * @member {Number} property_id
 */
AssessmentProposal.prototype['property_id'] = undefined;

/**
 * @member {Number} user_id
 */
AssessmentProposal.prototype['user_id'] = undefined;

/**
 * @member {Number} proposal_price
 */
AssessmentProposal.prototype['proposal_price'] = undefined;

/**
 * @member {String} proposal_content
 */
AssessmentProposal.prototype['proposal_content'] = undefined;

/**
 * @member {Boolean} is_user_read
 */
AssessmentProposal.prototype['is_user_read'] = undefined;

/**
 * @member {Boolean} is_user_approved
 */
AssessmentProposal.prototype['is_user_approved'] = undefined;

/**
 * @member {Number} trade_type
 */
AssessmentProposal.prototype['trade_type'] = undefined;

/**
 * @member {Date} proposal_date
 */
AssessmentProposal.prototype['proposal_date'] = undefined;

/**
 * @member {Date} created_at
 */
AssessmentProposal.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
AssessmentProposal.prototype['updated_at'] = undefined;

/**
 * @member {module:model/Agent} agent
 */
AssessmentProposal.prototype['agent'] = undefined;

/**
 * @member {module:model/User} user
 */
AssessmentProposal.prototype['user'] = undefined;

/**
 * @member {module:model/Property} property
 */
AssessmentProposal.prototype['property'] = undefined;

/**
 * @member {Array.<module:model/AssessmentProposalAttachmentFile>} assessment_proposal_attachment_files
 */
AssessmentProposal.prototype['assessment_proposal_attachment_files'] = undefined;

/**
 * @member {module:model/ProposalStatusEnum} proposal_status
 */
AssessmentProposal.prototype['proposal_status'] = undefined;






export default AssessmentProposal;

