/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Company from './Company';
import CompanyLogoImage from './CompanyLogoImage';
import IdName from './IdName';

/**
 * The CompanyRequest model module.
 * @module model/CompanyRequest
 * @version 1.0.0
 */
class CompanyRequest {
    /**
     * Constructs a new <code>CompanyRequest</code>.
     * @alias module:model/CompanyRequest
     * @implements module:model/Company
     * @param name {String} 
     */
    constructor(name) { 
        Company.initialize(this);
        CompanyRequest.initialize(this, name);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name) { 
        obj['name'] = name;
    }

    /**
     * Constructs a <code>CompanyRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompanyRequest} obj Optional instance to populate.
     * @return {module:model/CompanyRequest} The populated <code>CompanyRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompanyRequest();
            Company.constructFromObject(data, obj);

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('short_appeal_message')) {
                obj['short_appeal_message'] = ApiClient.convertToType(data['short_appeal_message'], 'String');
            }
            if (data.hasOwnProperty('achievements')) {
                obj['achievements'] = ApiClient.convertToType(data['achievements'], 'String');
            }
            if (data.hasOwnProperty('strengths_of_properties_for_sale_and_purchase')) {
                obj['strengths_of_properties_for_sale_and_purchase'] = ApiClient.convertToType(data['strengths_of_properties_for_sale_and_purchase'], 'String');
            }
            if (data.hasOwnProperty('strengths_of_partner_financial_institutions')) {
                obj['strengths_of_partner_financial_institutions'] = ApiClient.convertToType(data['strengths_of_partner_financial_institutions'], 'String');
            }
            if (data.hasOwnProperty('strengths_of_properties_for_management')) {
                obj['strengths_of_properties_for_management'] = ApiClient.convertToType(data['strengths_of_properties_for_management'], 'String');
            }
            if (data.hasOwnProperty('appeal_point')) {
                obj['appeal_point'] = ApiClient.convertToType(data['appeal_point'], 'String');
            }
            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('company_speciality_tag_ids')) {
                obj['company_speciality_tag_ids'] = ApiClient.convertToType(data['company_speciality_tag_ids'], ['Number']);
            }
            if (data.hasOwnProperty('company_logo_image')) {
                obj['company_logo_image'] = CompanyLogoImage.constructFromObject(data['company_logo_image']);
            }
            if (data.hasOwnProperty('speciality_tags')) {
                obj['speciality_tags'] = ApiClient.convertToType(data['speciality_tags'], [IdName]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CompanyRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CompanyRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CompanyRequest.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['short_appeal_message'] && !(typeof data['short_appeal_message'] === 'string' || data['short_appeal_message'] instanceof String)) {
            throw new Error("Expected the field `short_appeal_message` to be a primitive type in the JSON string but got " + data['short_appeal_message']);
        }
        // ensure the json data is a string
        if (data['achievements'] && !(typeof data['achievements'] === 'string' || data['achievements'] instanceof String)) {
            throw new Error("Expected the field `achievements` to be a primitive type in the JSON string but got " + data['achievements']);
        }
        // ensure the json data is a string
        if (data['strengths_of_properties_for_sale_and_purchase'] && !(typeof data['strengths_of_properties_for_sale_and_purchase'] === 'string' || data['strengths_of_properties_for_sale_and_purchase'] instanceof String)) {
            throw new Error("Expected the field `strengths_of_properties_for_sale_and_purchase` to be a primitive type in the JSON string but got " + data['strengths_of_properties_for_sale_and_purchase']);
        }
        // ensure the json data is a string
        if (data['strengths_of_partner_financial_institutions'] && !(typeof data['strengths_of_partner_financial_institutions'] === 'string' || data['strengths_of_partner_financial_institutions'] instanceof String)) {
            throw new Error("Expected the field `strengths_of_partner_financial_institutions` to be a primitive type in the JSON string but got " + data['strengths_of_partner_financial_institutions']);
        }
        // ensure the json data is a string
        if (data['strengths_of_properties_for_management'] && !(typeof data['strengths_of_properties_for_management'] === 'string' || data['strengths_of_properties_for_management'] instanceof String)) {
            throw new Error("Expected the field `strengths_of_properties_for_management` to be a primitive type in the JSON string but got " + data['strengths_of_properties_for_management']);
        }
        // ensure the json data is a string
        if (data['appeal_point'] && !(typeof data['appeal_point'] === 'string' || data['appeal_point'] instanceof String)) {
            throw new Error("Expected the field `appeal_point` to be a primitive type in the JSON string but got " + data['appeal_point']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['company_speciality_tag_ids'])) {
            throw new Error("Expected the field `company_speciality_tag_ids` to be an array in the JSON data but got " + data['company_speciality_tag_ids']);
        }
        // validate the optional field `company_logo_image`
        if (data['company_logo_image']) { // data not null
          CompanyLogoImage.validateJSON(data['company_logo_image']);
        }
        if (data['speciality_tags']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['speciality_tags'])) {
                throw new Error("Expected the field `speciality_tags` to be an array in the JSON data but got " + data['speciality_tags']);
            }
            // validate the optional field `speciality_tags` (array)
            for (const item of data['speciality_tags']) {
                IdName.validateJSON(item);
            };
        }

        return true;
    }


}

CompanyRequest.RequiredProperties = ["name"];

/**
 * @member {Number} id
 */
CompanyRequest.prototype['id'] = undefined;

/**
 * @member {String} name
 */
CompanyRequest.prototype['name'] = undefined;

/**
 * @member {String} short_appeal_message
 */
CompanyRequest.prototype['short_appeal_message'] = undefined;

/**
 * @member {String} achievements
 */
CompanyRequest.prototype['achievements'] = undefined;

/**
 * @member {String} strengths_of_properties_for_sale_and_purchase
 */
CompanyRequest.prototype['strengths_of_properties_for_sale_and_purchase'] = undefined;

/**
 * @member {String} strengths_of_partner_financial_institutions
 */
CompanyRequest.prototype['strengths_of_partner_financial_institutions'] = undefined;

/**
 * @member {String} strengths_of_properties_for_management
 */
CompanyRequest.prototype['strengths_of_properties_for_management'] = undefined;

/**
 * @member {String} appeal_point
 */
CompanyRequest.prototype['appeal_point'] = undefined;

/**
 * @member {Date} created_at
 */
CompanyRequest.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
CompanyRequest.prototype['updated_at'] = undefined;

/**
 * @member {Array.<Number>} company_speciality_tag_ids
 */
CompanyRequest.prototype['company_speciality_tag_ids'] = undefined;

/**
 * @member {module:model/CompanyLogoImage} company_logo_image
 */
CompanyRequest.prototype['company_logo_image'] = undefined;

/**
 * @member {Array.<module:model/IdName>} speciality_tags
 */
CompanyRequest.prototype['speciality_tags'] = undefined;


// Implement Company interface:
/**
 * @member {Number} id
 */
Company.prototype['id'] = undefined;
/**
 * @member {String} name
 */
Company.prototype['name'] = undefined;
/**
 * @member {String} short_appeal_message
 */
Company.prototype['short_appeal_message'] = undefined;
/**
 * @member {String} achievements
 */
Company.prototype['achievements'] = undefined;
/**
 * @member {String} strengths_of_properties_for_sale_and_purchase
 */
Company.prototype['strengths_of_properties_for_sale_and_purchase'] = undefined;
/**
 * @member {String} strengths_of_partner_financial_institutions
 */
Company.prototype['strengths_of_partner_financial_institutions'] = undefined;
/**
 * @member {String} strengths_of_properties_for_management
 */
Company.prototype['strengths_of_properties_for_management'] = undefined;
/**
 * @member {String} appeal_point
 */
Company.prototype['appeal_point'] = undefined;
/**
 * @member {Date} created_at
 */
Company.prototype['created_at'] = undefined;
/**
 * @member {Date} updated_at
 */
Company.prototype['updated_at'] = undefined;
/**
 * @member {Array.<Number>} company_speciality_tag_ids
 */
Company.prototype['company_speciality_tag_ids'] = undefined;
/**
 * @member {module:model/CompanyLogoImage} company_logo_image
 */
Company.prototype['company_logo_image'] = undefined;
/**
 * @member {Array.<module:model/IdName>} speciality_tags
 */
Company.prototype['speciality_tags'] = undefined;




export default CompanyRequest;

