/**
 * Propally Agent API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class DeviceTypeEnum.
* @enum {}
* @readonly
*/
export default class DeviceTypeEnum {
    
        /**
         * value: "ios"
         * @const
         */
        "ios" = "ios";

    
        /**
         * value: "android"
         * @const
         */
        "android" = "android";

    
        /**
         * value: "other"
         * @const
         */
        "other" = "other";

    
        /**
         * value: "unknown"
         * @const
         */
        "unknown" = "unknown";

    

    /**
    * Returns a <code>DeviceTypeEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/DeviceTypeEnum} The enum <code>DeviceTypeEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

